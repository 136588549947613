import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import NotFoundPage from "./pages/NotFound";
import HomePage from "./pages/Home";
import LoginPage from "./pages/Auth/Login";
import PasswordRecoveryPage from "./pages/Auth/Password/PasswordRecovery";
import SetNewPasswordPage from "./pages/Auth/Password/SetNewPassword";
import MainLayout from "./layouts";
import ProfilePage from "./pages/Profile";
import MessagesPage from "./pages/Messages";
import NotificationsPage from "./pages/Notifications";
import RegisterPage from "./pages/Auth/Register";
import CreateWebsitePage from "./pages/Website/Create";
import CreateWebsiteTemplate from "./pages/Website/Template";
import CreateWebsiteInfos from "./pages/Website/Infos";
import { API_LINK } from "./utils/constants";
import MyWebsitesPage from "./pages/Website/All";
import CRMContactsPage from "./pages/CRM/Contacts";
import CRMAddContactPage from "./pages/CRM/Contacts/Add";
import CRMViewContactPage from "./pages/CRM/Contacts/View";
import CRMEditContactPage from "./pages/CRM/Contacts/Edit";
import CRMTasksPage from "./pages/CRM/Tasks";
import CRMAddTaskPage from "./pages/CRM/Tasks/Add";
import CRMViewTaskPage from "./pages/CRM/Tasks/View";
import CRMEditTaskPage from "./pages/CRM/Tasks/Edit";
import PaymentsInvoicesPage from "./pages/Payments/Invoices";
import PaymentsAddInvoicePage from "./pages/Payments/Invoices/Add";
import NewsletterPage from "./pages/Newsletter";
import UsersPage from "./pages/Users";
import WebsitesDataCategoriesPage from "./pages/WebsitesData/Global/Categories";
import WebsitesDataBrandsPage from "./pages/WebsitesData/Stores/Brands";
import WebsitesDataProductsPage from "./pages/WebsitesData/Stores/Products";
import WebsitesDataProductPage from "./pages/WebsitesData/Stores/Products/Product";
import WebsitesDataOrdersPage from "./pages/WebsitesData/Stores/Orders";
import WebsitesDataCouponsPage from "./pages/WebsitesData/Stores/Coupons";
import WebsitesDataTrashedCartsPage from "./pages/WebsitesData/Stores/TrashedCarts";
import CRMEmailPage from "./pages/CRM/Email";
import MarketingSocialmediaPage from "./pages/Marketing/Socialmedia";
import PaymentsLinksPage from "./pages/Payments/Links";
import SettingsPage from "./pages/Settings";
import WebsitesDataPostsPage from "./pages/WebsitesData/Blogs/Posts";
import WebsitesDataCommentsPage from "./pages/WebsitesData/Blogs/Comments";
import ReportsVisitesPage from "./pages/Reports/Visites";
import MarketingSEOPage from "./pages/Marketing/SEO";
import SubscribtionPage from "./pages/Subscribtion";
import PackagesPage from "./pages/Subscribtion/Packages";
import SubscribePage from "./pages/Subscribtion/Subscribe";
import DomainesPage from "./pages/Domaines";
import BuyDomainePage from "./pages/Domaines/Buy";
import PayDomainPage from "./pages/Domaines/Buy/Pay";
import PaymentMethodsPage from "./pages/Payments/Methods";
import PaymentHistoriesPage from "./pages/Payments/Histories";
import NeedUpgradePage from "./pages/NeedUpgrade";
import BuilderPage from "./pages/Builder";
import WalletPage from "./pages/Wallet";
import BankInfosPage from "./pages/Wallet/BankInfos";
import AppsPage from "./pages/Apps";
import CMSFilesPage from "./pages/CMS/Files";
import CMSCollectionsPage from "./pages/CMS/Collections";
import { useWebsite } from "./context/Website";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [ isAuthenticatedLoading, setIsAuthenticatedLoading ] = useState(true);
  const [ subscribtionData, setSubscribtionData ] = useState(false);
  const website = useWebsite();

  useEffect(() => {
    const checkAuthentication = async () => {
      const user = localStorage.getItem('user');      
  
      if (user) {
        try {
          const userData = JSON.parse(user);
          if (userData.token) {
            fetch(API_LINK+'/login/check', {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                token: userData.token
              })
            })
            .then((response) => response.json())
            .then((json) => {              
              if(json.success) {
                if(json.user.status === 'blocked') {
                  handleLogout();
                  setIsAuthenticatedLoading();
                } else {
                  setIsAuthenticated(true);
                  getSubscribtion(userData.token);
                }
              } else {
                setIsAuthenticated(false);
                setIsAuthenticatedLoading(false);
              }
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
              setIsAuthenticated(false);
              setIsAuthenticatedLoading(false);
            });
          } else {
            setIsAuthenticated(false);
            setIsAuthenticatedLoading(false);
          }
        } catch (error) {
          console.error('Error parsing user data:', error);
          setIsAuthenticated(false);
          setIsAuthenticatedLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setIsAuthenticatedLoading(false);
      }
    };
  
    checkAuthentication();
  }, []);  

  const getSubscribtion = (userId) => {    
    if(JSON.parse(website.website)?.id) {
      fetch(API_LINK+'/subscribtion/show/'+JSON.parse(website.website)?.id, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+userId
        }
      })
      .then((response) => response.json())
      .then((json) => {
        if(json.success) {
          setSubscribtionData(json.subscribtion);
          setIsAuthenticatedLoading(false);
        }
      })
    } else {
      setIsAuthenticatedLoading(false);
    }
  }

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  if(isAuthenticatedLoading) {
    return(
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '100vh'}}>
        <img src={require('./assets/brand-black.png')} width="80px" className="animation-spin" />
      </div>
    );
  } else {
    return (
      <BrowserRouter>
          <Routes>
              <Route path='/' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <LoginPage onLogin={handleLogin} />} />
              <Route path='/login' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <LoginPage onLogin={handleLogin} />} />
              <Route path='/register' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <RegisterPage onLogin={handleLogin} />} />
              <Route path='/password/recovery' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <PasswordRecoveryPage />} />
              <Route path='/password/new/:email' element={isAuthenticated ? <Navigate to={'/dashboard/home'} /> : <SetNewPasswordPage />} />
  
              <Route path='*' element={<NotFoundPage />} />
  
              <Route path='/website/new' element={isAuthenticated ? <CreateWebsitePage /> : <Navigate to={'/login'} />} />
              <Route path='/website/template/:category' element={isAuthenticated ? <CreateWebsiteTemplate /> : <Navigate to={'/login'} />} />
              <Route path='/website/infos/:template' element={isAuthenticated ? <CreateWebsiteInfos /> : <Navigate to={'/login'} />} />
  
              <Route path='/dashboard' element={isAuthenticated ? <MainLayout onLogout={handleLogout} /> : <Navigate to={'/login'} />}>
                <Route path='/dashboard/home' element={<HomePage />} />
                <Route path='/dashboard/settings' element={<SettingsPage />} />
                <Route path='/dashboard/subscribtion' element={<SubscribtionPage />} />
                <Route path='/dashboard/packages' element={<PackagesPage />} />
                <Route path='/dashboard/subscribe/:package' element={<SubscribePage />} />
                <Route path='/dashboard/users' element={<UsersPage />} />
                <Route path='/dashboard/payment_methods' element={<PaymentMethodsPage />} />
                <Route path='/dashboard/payment_histories/:page?' element={<PaymentHistoriesPage />} />
                <Route path='/dashboard/wallet' element={<WalletPage />} />
                <Route path='/dashboard/bank_informations' element={<BankInfosPage />} />
                <Route path='/dashboard/newsletter/:page?' element={<NewsletterPage />} />
  
                <Route path='/dashboard/categories' element={<WebsitesDataCategoriesPage />} />
                <Route path='/dashboard/store/brands' element={<WebsitesDataBrandsPage />} />
                <Route path='/dashboard/store/products/:page?' element={<WebsitesDataProductsPage />} />
                <Route path='/dashboard/store/product/:id?' element={<WebsitesDataProductPage />} />
                <Route path='/dashboard/store/orders/:page?' element={<WebsitesDataOrdersPage />} />
                <Route path='/dashboard/store/coupons/:page?' element={<WebsitesDataCouponsPage />} />
                <Route path='/dashboard/store/cart_trash/:page?' element={<WebsitesDataTrashedCartsPage />} />
  
                <Route path='/dashboard/blog/posts' element={<WebsitesDataPostsPage />} />
                <Route path='/dashboard/blog/comments' element={<WebsitesDataCommentsPage />} />
  
                <Route path='/dashboard/crm/contacts/:page?' element={<CRMContactsPage />} />
                <Route path='/dashboard/crm/contact/add' element={<CRMAddContactPage />} />
                <Route path='/dashboard/crm/contact/view/:contact' element={<CRMViewContactPage />} />
                <Route path='/dashboard/crm/contact/edit/:contact' element={<CRMEditContactPage />} />
                
                <Route path='/dashboard/crm/tasks/:page?' element={<CRMTasksPage />} />
                <Route path='/dashboard/crm/task/add' element={<CRMAddTaskPage />} />
                <Route path='/dashboard/crm/task/view/:task' element={<CRMViewTaskPage />} />
                <Route path='/dashboard/crm/task/edit/:task' element={<CRMEditTaskPage />} />
  
                <Route path='/dashboard/crm/webmail/:page?' element={<CRMEmailPage />} />

                <Route path='/dashboard/cms/files/:folder?' element={<CMSFilesPage />} />
                <Route path='/dashboard/cms/collections' element={<CMSCollectionsPage />} />
  
                <Route path='/dashboard/marketing/socialmedia' element={<MarketingSocialmediaPage />} />
                <Route path='/dashboard/marketing/seo' element={<MarketingSEOPage />} />
  
                <Route path='/dashboard/payments/invoices/:page?' element={Number(subscribtionData?.package?.accept_payments) > 0 ? <PaymentsInvoicesPage /> : <NeedUpgradePage />} />
                <Route path='/dashboard/payments/invoice/:id?' element={Number(subscribtionData?.package?.accept_payments) > 0 ? <PaymentsAddInvoicePage /> : <NeedUpgradePage />} />
                <Route path='/dashboard/payments/payment_links/:page?' element={Number(subscribtionData?.package?.accept_payments) > 0 ? <PaymentsLinksPage /> : <NeedUpgradePage />} />
  
                <Route path='/dashboard/reports/visites' element={<ReportsVisitesPage />} />
  
                <Route path='/dashboard/profile' element={<ProfilePage />} />
                <Route path='/dashboard/domaines' element={<DomainesPage />} />
                <Route path='/dashboard/domain/buy' element={<BuyDomainePage />} />
                <Route path='/dashboard/domain/pay' element={<PayDomainPage />} />
                <Route path='/dashboard/messages' element={<MessagesPage />} />
                <Route path='/dashboard/notifications' element={<NotificationsPage />} />
                <Route path='/dashboard/websites/all' element={isAuthenticated ? <MyWebsitesPage /> : <Navigate to={'/login'} />} />
                
                <Route path='/dashboard/apps' element={isAuthenticated ? <AppsPage /> : <Navigate to={'/login'} />} />
              </Route>

              <Route path='/build/:website' element={isAuthenticated ? <BuilderPage /> : <Navigate to={'/login'} />} />
          </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
