import React, { useEffect, useState } from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { DefaultInput } from "../../../components/Forms/Inputs/default";
import { API_LINK, MAIN_LINK } from "../../../utils/constants";
import { useWebsite } from "../../../context/Website";
import { DefaultButton } from "../../../components/Buttons/default";
import { extractUrlFromBackgroundImage } from "../../../utils/helpers";
import { FaImage } from "react-icons/fa6";
import BuilderImagesModal from "../Modals/Images";
import { i18n } from "../../../i18n";

const BuilderSectionSettingsModal = (props) => {
  const website = useWebsite();
  const [fontSizeUnit, setFontSizeUnit] = useState('px');
  const [clonedData, setClonedData] = useState(props.data);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [edited, setEdited] = useState(false);
  const [galleryModalShown, setGalleryModalShown] = useState(false);

  const updateIframe = () => {
    const iframe = document.getElementById('builder-iframe');
    const message = {
      type: 'updateSection',
      sectionIndex: clonedData.parentInfo.sectionIndex,
      tagName: clonedData.parentInfo.tagName,
      backgroundColor: clonedData.backgroundColor,
      backgroundImage: clonedData.backgroundImage,
      paddingTop: clonedData.paddingTop?.includes('px') ? clonedData.paddingTop : clonedData.paddingTop+fontSizeUnit,
      paddingRight: clonedData.paddingRight?.includes('px') ? clonedData.paddingRight : clonedData.paddingRight+fontSizeUnit,
      paddingLeft: clonedData.paddingLeft?.includes('px') ? clonedData.paddingLeft : clonedData.paddingLeft+fontSizeUnit,
      paddingBottom: clonedData.paddingBottom?.includes('px') ? clonedData.paddingBottom : clonedData.paddingBottom+fontSizeUnit,
      marginTop: clonedData.marginTop?.includes('px') ? clonedData.marginTop : clonedData.marginTop+fontSizeUnit,
      marginRight: clonedData.marginRight?.includes('px') ? clonedData.marginRight : clonedData.marginRight+fontSizeUnit,
      marginLeft: clonedData.marginLeft?.includes('px') ? clonedData.marginLeft : clonedData.marginLeft+fontSizeUnit,
      marginBottom: clonedData.marginBottom?.includes('px') ? clonedData.marginBottom : clonedData.marginBottom+fontSizeUnit,
      borderRadius: clonedData.borderRadius+'px'
    };

    iframe.contentWindow.postMessage(message, '*');
  }

  useEffect(() => {
    updateIframe();
  }, [edited, props.data.src]);

  useEffect(() => {
    updateBackend();
  }, [props.needUpdate]);

  const updateBackend = (action = null) => {
    setUpdateLoading(true);
    const user = localStorage.getItem('user');

    fetch(API_LINK+'/builder/section/settings/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+JSON.parse(user).token
      },
      body: JSON.stringify({
        website_id: JSON.parse(website.website).id,
        page_id: props.selectedPage.id,
        lang: props.selectedLanguage.language.short,
        sectionIndex: clonedData.parentInfo.sectionIndex,
        tagName: clonedData.parentInfo.tagName,
        backgroundColor: clonedData.backgroundColor,
        backgroundImage: clonedData.backgroundImage,
        paddingTop: clonedData.paddingTop?.includes('px') ? clonedData.paddingTop : clonedData.paddingTop+fontSizeUnit,
        paddingRight: clonedData.paddingRight?.includes('px') ? clonedData.paddingRight : clonedData.paddingRight+fontSizeUnit,
        paddingLeft: clonedData.paddingLeft?.includes('px') ? clonedData.paddingLeft : clonedData.paddingLeft+fontSizeUnit,
        paddingBottom: clonedData.paddingBottom?.includes('px') ? clonedData.paddingBottom : clonedData.paddingBottom+fontSizeUnit,
        marginTop: clonedData.marginTop?.includes('px') ? clonedData.marginTop : clonedData.marginTop+fontSizeUnit,
        marginRight: clonedData.marginRight?.includes('px') ? clonedData.marginRight : clonedData.marginRight+fontSizeUnit,
        marginLeft: clonedData.marginLeft?.includes('px') ? clonedData.marginLeft : clonedData.marginLeft+fontSizeUnit,
        marginBottom: clonedData.marginBottom?.includes('px') ? clonedData.marginBottom : clonedData.marginBottom+fontSizeUnit,
        borderRadius: clonedData.borderRadius?.includes('px') ? clonedData.borderRadius : clonedData.borderRadius+fontSizeUnit
      })
    })
    .then((response) => response.json())
    .then((json) => {
      if(json.success) {
        if(action === 'close') {
          props.toggle();
        }

        setClonedData(props.data);
      }

      setUpdateLoading(false);
    })
  }

  const handleClonedDataChange = (event) => {
    const { name, value } = event.target;

    setClonedData(prevState => ({
      ...prevState,
      [name]: value,
    }));

    props.handleChange(event);

    setEdited(!edited);
  }

  const extractNumberAndUnit = (value) => {
    const matches = value?.match(/(\d+)(\D+)/);
    if (matches) {
      return {
        number: Number(matches[1]),
        unit: matches[2].trim()
      };
    }
    return null;
  }

  const handleImageChange = (image) => {
    const URL = image.largeImageURL || MAIN_LINK+image.file_url;
    setClonedData(prevState => ({
      ...prevState,
      'backgroundImage': 'url("'+URL+'")',
    }));

    props.handleChange(
      {target: {
        name: 'backgroundImage',
        value: 'url("'+URL+'")'
      }}
    );

    setEdited(!edited);
    setGalleryModalShown(false);
  }

  return(
    <div className="builder-edit-container">
      {updateLoading &&
        <div className="builder-edit-loading">
          <img src={require('../../../assets/brand-black.png')} width="60px" className="animation-spin" />
        </div>
      }
      <div className="builder-add-header p-3 border-bottom">
        <Row className="align-items-center">
          <Col md="8" xs="8">
            <h5 className="fw-bold mb-0">{ i18n.t('builder.sidebar.titles.edit_section') }</h5>
          </Col>
          <Col md="4" xs="4" className="text-end">
            <DefaultButton size={'sm'} color={'dark'} onClick={() => updateBackend('close')}>حفظ</DefaultButton>
          </Col>
        </Row>
      </div>
      <div className="builder-add-body px-3 pt-3">
        <h5 className="mb-2 fw-bold">{ i18n.t('builder.sidebar.background_title') }</h5>
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.background_color') }: </Label>
          <Input 
            type={'color'} 
            size={'md'}
            className="p-0 rounded-0 border-0"
            name="backgroundColor"
            onChange={(event) => handleClonedDataChange(event)}
            value={props.data.backgroundColor}
          />
        </FormGroup>
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.background_image') }: </Label>
          <div className="builder-add-image-picker position-relative">
            <img 
              src={extractUrlFromBackgroundImage(props.data.backgroundImage)} 
              alt="" 
              width={'100%'} 
              className="border" 
            />
            <div 
              className="builder-add-overlay align-items-center justify-content-center" 
              onClick={() => setGalleryModalShown(true)}
            >
              <FaImage size={80} color="#fff" />
            </div>
          </div>
        </FormGroup>

        <hr />

        <h5 className="mb-2 fw-bold">{ i18n.t('builder.sidebar.border_title') }</h5>
        <FormGroup>
          <Label>{ i18n.t('builder.sidebar.border_radius') }: </Label>
          <Input 
            type="range" 
            name="borderRadius"
            value={extractNumberAndUnit(props.data.borderRadius)?.number || props.data.borderRadius}
            onChange={(event) => handleClonedDataChange(event)}
            step={4}
          />
        </FormGroup>

        <hr />

        <h5 className="mb-2 fw-bold">{ i18n.t('builder.sidebar.padding_title') }</h5>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.top') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'paddingTop'}
                value={extractNumberAndUnit(props.data.paddingTop)?.number || props.data.paddingTop}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.bottom') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'paddingBottom'}
                value={extractNumberAndUnit(props.data.paddingBottom)?.number || props.data.paddingBottom}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.right') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'paddingRight'}
                value={extractNumberAndUnit(props.data.paddingRight)?.number || props.data.paddingRight}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.left') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'paddingLeft'}
                value={extractNumberAndUnit(props.data.paddingLeft)?.number || props.data.paddingLeft}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
        </Row>

        <hr />

        <h5 className="mb-2 fw-bold">{ i18n.t('builder.sidebar.margin_title') }</h5>
        <Row>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.top') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginTop'}
                value={extractNumberAndUnit(props.data.marginTop)?.number || props.data.marginTop}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.bottom') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginBottom'}
                value={extractNumberAndUnit(props.data.marginBottom)?.number || props.data.marginBottom}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.right') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginRight'}
                value={extractNumberAndUnit(props.data.marginRight)?.number || props.data.marginRight}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
          <Col xs="6">
            <FormGroup>
              <Label>{ i18n.t('builder.sidebar.left') }: </Label>
              <DefaultInput 
                type={'number'} 
                size={'md'} 
                name={'marginLeft'}
                value={extractNumberAndUnit(props.data.marginLeft)?.number || props.data.marginLeft}
                onChange={(event) => handleClonedDataChange(event)}
                step={4}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>

      <BuilderImagesModal 
        isOpen={galleryModalShown}
        toggle={() => setGalleryModalShown(false)}
        onConfirm={(image) => handleImageChange(image)}
      />
    </div>
  );
}

export default BuilderSectionSettingsModal;