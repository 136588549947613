import React, { Component } from "react";
import { Container } from "reactstrap";
import { AdminBodyTitle } from "../../../../components/Typography/Titles";

class WebsitesDataPostsPage extends Component {
  render() {
    return(
      <Container>
        <AdminBodyTitle>المقالات</AdminBodyTitle>
      </Container>
    );
  }
}

export default WebsitesDataPostsPage;